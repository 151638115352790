@tailwind base;
@tailwind components;
@tailwind utilities;

ul {
    @apply list-disc list-inside;
}

nav .pages a {
    @apply px-2 py-1
}

nav .pages a:is(.active, :hover) {
    @apply bg-gray-300
}

.content a {
    @apply text-blue-600
}

.content a:visited {
    @apply text-pink-900
}

.resume h1 {
    @apply text-3xl md:text-5xl
}

.resume h2 {
    @apply text-2xl md:text-3xl
}

.resume h3 {
    @apply text-lg md:text-xl
}

.resume h4 {
    @apply md:text-lg
}

.resume .contact {
    @apply text-sm md:text-base
}

.resume .contact .label {
    @apply text-gray-500 sm:float-right ml-1
}
